import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { currencyFormat, pad } from "../../../function/fn";
import Logo from "../../../assets/logo-ssc.png";
import reportAddTimesheet from "../tableColumn/reportAddTimesheet";
import reportPrintTimesheet from "../tableColumn/reportPrintTimesheet";
import summaryPrintCol from "../tableColumn/summaryPrintCol";
import locationOnlyColumn from "../../monthlypaid/tableColumn/locationOnlyColumn";

export default class PrintReport extends Component {
  render() {
    const data = this.props?.data;
    const location = this.props?.location;
    const loading = this.props?.loading;
    const dateSelect = this.props?.dateSelect;

    console.log(data);

    let no = 1;

    if (loading) {
      return (
        <center>
          <LoadingOutlined style={{ fontSize: 40, color: "#29aae1" }} />
        </center>
      );
    }

    return (
      <>
        <Row>
          <Col xs={24} style={{ padding: "10px 20px", height: "auto" }}>
            {/* , height: 793 */}
            <table style={{ width: "100%", marginBottom: 20 }}>
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <img src={Logo} style={{ width: 90 }} />
                  </td>
                  <td style={{ width: "20%" }}></td>
                  <td
                    style={{
                      width: "50%",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    <div>
                      <b>
                        SALARY PAYMENT in{" "}
                        {moment(dateSelect).format("MMM YYYY")?.toUpperCase()}
                      </b>
                    </div>
                    {location !== "" && (
                      <b style={{ fontSize: 14 }}>{location}</b>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>

            <Table
              // showHeader={false}
              bordered
              className="table-print-header"
              columns={summaryPrintCol()}
              dataSource={[]}
              pagination={false}
            />

            {data?.data?.map((e, index) => {
              let tableDataWithNo = [];

              let total = 0;

              e?.instructors?.map((record, index) => {
                let data = { ...record, no: no };
                total += parseFloat(data?.amount);
                tableDataWithNo.push(data);
                no += 1;
              });

              console.log(total);

              return (
                <div key={index}>
                  <Table
                    showHeader={false}
                    bordered
                    className="table-print-summary"
                    columns={locationOnlyColumn()}
                    dataSource={[{ location: e?.location }]}
                    rowKey={(record) => record?.location}
                    pagination={false}
                  />
                  <Table
                    showHeader={false}
                    bordered
                    className="table-print-summary"
                    columns={summaryPrintCol()}
                    dataSource={tableDataWithNo}
                    rowKey={(record) => record?.pt_id}
                    rowClassName={(record) =>
                      record?.onuse !== "yes" ? "deleted-row" : ""
                    }
                    pagination={false}
                    sticky
                    loading={loading}
                    // scroll={{ x: 500 }}
                    // footer={[<div>total</div>]}
                  />
                  <Row>
                    <Col
                      xs={{ offset: 14, span: 10 }}
                      style={{ padding: 10, textAlign: "right" }}
                    >
                      <h4>total : {currencyFormat(total)}</h4>
                    </Col>
                  </Row>
                </div>
              );
            })}

            <br />

            <Row>
              <Col
                xs={{ offset: 14, span: 10 }}
                style={{ padding: 10, textAlign: "right" }}
              >
                <h4>
                  <b>Total : {currencyFormat(data?.sumPaid)}</b>
                </h4>
              </Col>
            </Row>

            <br />
            <br />
            <Row>
              <Col xs={8} className="col-foot">
                <div>
                  <b>Received by</b>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div>Date:</div>
                </div>
              </Col>

              <Col xs={8} className="col-foot">
                <div>
                  <b>Checked by</b>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div>Date:</div>
                </div>
              </Col>

              <Col xs={8} className="col-foot">
                <div>
                  <b>Prepared by</b>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div>Date:</div>
                </div>
              </Col>
            </Row>

            <div className={"footer-container"}>
              <Row>
                <Col xs={24} style={{ paddingTop: 5 }}>
                  <div className="col-foot">
                    012 797 085 | 015 382 803 | srsurvivalswim@gmail.com |
                    survivalswimclub.com
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
