import { randomFn } from "./dev";
import {
  openErrorNotification,
  openSuccessNotification,
  openWarningNotification,
} from "./own-comp";
import { Select } from "antd";

const { Option } = Select;
const axios = require("axios");

export const actionData = require("../assets/actionlog/action.json");

export function currencyFormat(num) {
  num = num > 0 ? parseFloat(num) : 0;
  return "$" + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
}

export const getTimesheetWithPagination = async (
  page,
  pageSize,
  date,
  classFilter,
  instructorFilter,
  detailFilter,
  locationFilter,
  sorter,
  statusFilter
) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("page", page);
  params.append("pageSize", parseInt(pageSize));

  params.append("startDate", date?.from !== null ? date?.from : "");
  params.append("endDate", date?.to !== null ? date?.to : "");
  params.append("classFilter", classFilter !== null ? classFilter : "");
  params.append(
    "instructorFilter",
    instructorFilter !== null ? instructorFilter : ""
  );
  params.append("detailFilter", detailFilter !== null ? detailFilter : "");
  params.append(
    "locationFilter",
    locationFilter !== null ? locationFilter : ""
  );
  params.append("statusFilter", statusFilter !== null ? statusFilter : "");
  params.append("sorter", sorter !== null ? sorter : "");

  // wait...
  await randomFn();

  return await axios
    .post(
      `${process.env.React_App_URL}get/getTimesheetWithPagination.php`,
      params
    )
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export const getReportWithPagination = async (page, pageSize, voidFilter) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("page", page);
  params.append("pageSize", parseInt(pageSize));
  params.append("voidFilter", voidFilter !== null ? voidFilter : "");

  // wait...
  await randomFn();

  return await axios
    .post(`${process.env.React_App_URL}get/getReportWithPagination.php`, params)
    .then(async function (response) {
      // console.log(response?.data)
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export const getMondlyPaidWithPagination = async (page, pageSize) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("page", page);
  params.append("pageSize", parseInt(pageSize));

  // wait...
  await randomFn();

  return await axios
    .post(
      `${process.env.React_App_URL}get/getMonthlyPaidWithPagination.php`,
      params
    )
    .then(async function (response) {
      // console.log(response?.data)
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export const deleteTimesheet = async (id) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("ts_id", id);

  return await axios
    .post(`${process.env.React_App_URL}delete/deleteTimesheet.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "failed" &&
        (await response?.data) !== "notuser"
      ) {
        openSuccessNotification({
          title: "Success",
          message: "Timesheet deleted!",
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const updateTimesheet = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  return await axios
    .post(
      `${process.env.React_App_URL}update/updateTimesheetStatus.php`,
      params
    )
    .then(async function (response) {
      if (
        (await response?.data) !== "failed" &&
        (await response?.data) !== "notuser"
      ) {
        openSuccessNotification({
          title: "Success",
          message: "Timesheet updated!",
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const updatePaymentTimesheetVoid = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  return await axios
    .post(
      `${process.env.React_App_URL}update/updatePayTimesheetVoid.php`,
      params
    )
    .then(async function (response) {
      if ((await response?.data?.trim()) === "success") {
        openWarningNotification({
          title: "Void",
          message: `This has been void!`,
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const isUser = async () => {
  // const params = new URLSearchParams();
  // params.append('db_user', process.env.React_App_DB_USER);
  // params.append('db_password',process.env.React_App_DB_PASSWORD);
  // params.append('db', process.env.React_App_DB);

  // params.append('username',data?.username)
  // params.append('inst_id',data?.inst_id)

  return await axios
    .post(`${process.env.React_App_URL}login/getSession.php`)
    .then(function (response) {
      return response?.data;
    });
};

export const updateTimesheetById = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  return await axios
    .post(`${process.env.React_App_URL}update/updateTimesheetById.php`, params)
    .then(async function (response) {
      if (response?.data === "success") {
        openSuccessNotification({
          title: "Success",
          message: "Timesheet updated!",
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const createTimesheet = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  return await axios
    .post(`${process.env.React_App_URL}insert/createTimesheetAdmin.php`, params)
    .then(async function (response) {
      if (response?.data?.toString().trim() === "success") {
        openSuccessNotification({
          title: "Success",
          message: "Timesheet created!",
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const getInstructorList = async () => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  return await axios
    .post(`${process.env.React_App_URL}get/getInstructor.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export const getStaffList = async () => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  return await axios
    .post(`${process.env.React_App_URL}get/getStaff.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export const getLocationList = async () => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  return await axios
    .post(`${process.env.React_App_URL}get/getLocation.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function delete_cookie(name) {
  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const time = () => {
  let data = [];
  for (let i = 6; i <= 20; i++) {
    for (let j = 0; j < 4; j++) {
      let min = j <= 1 ? "00" : "30";
      let minTo = j === 0 || j === 3 ? "30" : "00";

      let hourTo = j > 0 ? i + 1 : i;

      let time = `${i}:${min}-${hourTo}:${minTo}`;

      data.push(
        <Option key={time} value={time}>
          {time}
        </Option>
      );
    }
  }

  return data.sort();
};

export const checkUpperCase = (strings) => {
  let shortCut = [];
  let character = "";
  for (let i = 0; i <= strings.length; i++) {
    character = strings.charAt(i);
    // if (!isNaN(character * 1)){
    //     alert('character is numeric');
    // }else{
    if (character !== " " && character === character.toUpperCase()) {
      shortCut.push(character.toUpperCase());
    }

    // }
  }
  return shortCut.join("");
};

export const getCountTimesheet = async () => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  return await axios
    .post(`${process.env.React_App_URL}get/getCountData.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return null;
      }
    });
};

export function keyMenu(e) {
  const myArr = e.split("/");
  let x = "";

  if (myArr[1] === "report") {
    x = "/report";
  } else if (myArr[1] === "payment") {
    x = "/payment";
  } else {
    x = "/";
  }

  return x;
}

export const getToPayTimesheet = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  // wait...
  await randomFn();

  return await axios
    .post(`${process.env.React_App_URL}get/getToPayTimesheet.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export const createPayment = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  return await axios
    .post(
      `${process.env.React_App_URL}insert/createPaymentTimesheet.php`,
      params
    )
    .then(async function (response) {
      // console.log(response)
      if (response?.data?.toString().trim() === "success") {
        openSuccessNotification({
          title: "Success",
          message: "Payment created!",
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const createMondlyPaid = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  return await axios
    .post(`${process.env.React_App_URL}insert/createMondlyPaid.php`, params)
    .then(async function (response) {
      // console.log(response)
      if (response?.data?.toString().trim() === "success") {
        openSuccessNotification({ title: "Success", message: "Saved!" });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const createVK = async (data) => {
  const params = new FormData();
  params.append("db_user", "wwvka_vkms");
  params.append("db_password", "v42@*=doQUXA");
  params.append("db", "wwvka_vkms");

  params.append("data", JSON.stringify(data));
  params.append("startImage", data.startImage);

  return await axios
    .post(
      `https://system.vkangkor.com/create/createDailyConstruct.php`,
      params,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then(async function (response) {
      console.log(response);
      if (response?.data?.toString().trim() === "success") {
        openSuccessNotification({
          title: "Success",
          message: "Payment created!",
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const sumTotalHours = (data = []) => {
  const newData = data?.map((e) => e?.time);
  let totalHOurs = 0;

  newData?.map((e) => {
    let time = e?.split("-");
    if (
      (time[0]?.includes("30") && time[1]?.includes("30")) ||
      (time[0]?.includes("00") && time[1]?.includes("00"))
    ) {
      totalHOurs += 1;
    } else {
      totalHOurs += 0.5;
    }
  });

  return totalHOurs;
};

export const getTimesheetByPayment = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  // wait...
  await randomFn();

  return await axios
    .post(`${process.env.React_App_URL}get/getTimesheetByPayment.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        // console.log(response?.data)
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export const deletePaymentTimesheetById = async (id) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("pt_id", id);

  return await axios
    .post(
      `${process.env.React_App_URL}delete/deletePaymentTimesheetById.php`,
      params
    )
    .then(async function (response) {
      if (
        (await response?.data) !== "failed" &&
        (await response?.data) !== "notuser"
      ) {
        openSuccessNotification({
          title: "Success",
          message: "Timesheet deleted!",
        });
        return true;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return false;
      }
    });
};

export const sumArr = (e) => {
  const total = e?.reduce((prev, current) => {
    return parseFloat(prev) + parseFloat(current);
  }, 0);
  return total;
};

export const pad = (n) => {
  return new Array(n).join("0").slice((n || 2) * -1) + this;
};

export const getSummaryReportByMonth = async (data) => {
  const params = new URLSearchParams();
  params.append("db_user", process.env.React_App_DB_USER);
  params.append("db_password", process.env.React_App_DB_PASSWORD);
  params.append("db", process.env.React_App_DB);

  params.append("data", JSON.stringify(data));

  // wait...
  await randomFn();

  return await axios
    .post(`${process.env.React_App_URL}get/getSummaryReportByMonth.php`, params)
    .then(async function (response) {
      if (
        (await response?.data) !== "Cannot select" &&
        (await response?.data) !== "notuser"
      ) {
        return response?.data;
      } else {
        openErrorNotification({ title: "Failed", message: response.data });
        return [];
      }
    });
};

export function getMonthFromString(mon) {
  var d = Date.parse(`${mon} 1, ${new Date().getFullYear()}`);
  if (!isNaN(d)) {
    return new Date(d).getMonth() + 1;
  }
  return -1;
}

export const getSumReportByLocation = (arr, location) => {
  console.log(arr, location, "test")
  let sum = arr.reduce(function (a, b) {
    return parseFloat(a) + parseFloat(b["amount"]);
  }, 0);

  return sum;
};
