import {
  Breadcrumb,
  Col,
  Row,
  Table,
  DatePicker,
  Select,
  Dropdown,
  Button,
} from "antd";
import React, { useEffect, useState } from "react";
import timesheetCol from "../component/timesheet/tableColumn/timesheetCol";
import {
  currencyFormat,
  deleteTimesheet,
  getCountTimesheet,
  getTimesheetWithPagination,
  updateTimesheet,
} from "../function/fn";
import moment from "moment";
import { menu, SelectInstructor, SelectLocation } from "../function/own-comp";
import EditTimesheet from "../component/timesheet/modal/editTimesheet";
import AddTimesheet from "../component/timesheet/modal/addTimesheet";

const { RangePicker } = DatePicker;
const { Option } = Select;

export default function Timesheet() {
  const [dataList, setDataList] = useState({});
  const [success, setSuccess] = useState(false);

  // const [countAllData,setCountAllData] = useState(null)

  const [loading, setLoading] = useState(true);
  const [paidLoading, setPaidLoading] = useState(false);
  const [pendingLoading, setPendingLoading] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const [openAdd, setOpenAdd] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [classFilter, setClassFilter] = useState(null);
  const [instructorFilter, setInstructorFilter] = useState(null);
  const [detailFilter, setDetailFilter] = useState(null);
  const [locationFilter, setLocationFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [sorter, setSorter] = useState(null);

  const [sumAmount, setSumAmount] = useState(0);
  const [sumUnpaid, setSumUnpaid] = useState(0);
  const [sumPaid, setSumPaid] = useState(0);

  const [totalTimesheet, setTotalTimesheet] = useState(null);

  const [range, setRange] = useState({
    from: null,
    to: null,
  });

  //select row update
  const onSelectChange = (selectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', selectedRowKeys);
    setSelectedRow([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRow,
    onChange: onSelectChange,
  };

  //end-----

  async function fetchData() {
    let data = await getTimesheetWithPagination(
      page,
      pageSize,
      range,
      classFilter,
      instructorFilter,
      detailFilter,
      locationFilter,
      sorter,
      statusFilter
    );
    setDataList(data);
    setTotalTimesheet(data?.totalDoc);

    setSumAmount(data?.sumAmount);
    setSumPaid(data?.sumPaid);
    setSumUnpaid(data?.sumUnpaid);
    setLoading(false);
    setSuccess(false);
  }

  useEffect(() => {
    setLoading(true);
    setSelectedRow([]);

    fetchData();
  }, [
    page,
    pageSize,
    range,
    classFilter,
    success,
    instructorFilter,
    detailFilter,
    locationFilter,
    sorter,
    statusFilter,
  ]);

  const handleReset = () => {
    setSelectedRow([]);
    setRange({ from: null, to: null });
    setClassFilter(null);
    setInstructorFilter(null);
    setDetailFilter(null);
    setLocationFilter(null);
    setStatusFilter(null);
  };

  const handleSelectDate = (e) => {
    setSelectedRow([]);
    if (e) {
      // console.log('test')
      setLoading(true);
      setRange({
        from: moment(e[0]).format("YYYY-MM-DD").toString(),
        to: moment(e[1]).format("YYYY-MM-DD").toString(),
      });
    } else {
      setLoading(true);
      setRange({
        from: null,
        to: null,
      });
    }
  };

  const handleClass = (e) => {
    setSelectedRow([]);
    setClassFilter(e);
  };

  const handleDetail = (e) => {
    setSelectedRow([]);
    setDetailFilter(e);
  };

  const handleStatus = (e) => {
    setSelectedRow([]);
    setStatusFilter(e);
  };

  const handleDelete = (e) => {
    setLoading(true);
    async function deleteData() {
      if (await deleteTimesheet(e)) {
        setSuccess(true);
      }
    }
    deleteData();
  };

  const handleUpdate = (e) => {
    e?.status === "paid" ? setPaidLoading(true) : setPendingLoading(true);
    async function updateData() {
      if (await updateTimesheet(e)) {
        setSuccess(true);
        setPaidLoading(false);
        setPendingLoading(false);
      }
    }
    updateData();
  };

  const handleEdit = (e) => {
    setOpenEdit(true);
    setEditData(e);
  };

  const setToInstructorFn = (e) => {
    setSelectedRow([]);
    setInstructorFilter(e);
  };

  const setToLocationFn = (e) => {
    setSelectedRow([]);
    setLocationFilter(e);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSorter(sorter?.order);
    // this.fetch({
    //   sortField: sorter.field,
    //   sortOrder: sorter.order,
    //   pagination,
    //   ...filters,
    // });
  };

  let tableDataWithNo = [];

  dataList?.data?.map((record, index) => {
    let pageAdd = page > 1 ? page * pageSize - pageSize + 1 : 1;

    let data = { ...record, no: dataList?.totalDoc - (pageAdd + index) + 1 };
    tableDataWithNo.push(data);
  });

  return (
    <>
      <EditTimesheet
        open={openEdit}
        setOpen={setOpenEdit}
        data={editData}
        setSuccess={setSuccess}
      />
      <AddTimesheet
        open={openAdd}
        setOpen={setOpenAdd}
        setSuccess={setSuccess}
      />
      <Row>
        {/* <Col xs={24} style={{ padding: 10 }}>
                    <Breadcrumb style={{ margin: '0px 0', }}>
                        <Breadcrumb.Item href="../">
                            <Dropdown overlay={menu} >
                                <span className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                Menu

                                </span>
                            </Dropdown>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Timesheet</Breadcrumb.Item>
                    </Breadcrumb>
                </Col> */}

        <Col xs={24} style={{ padding: 10 }}>
          <h2>Timesheet </h2>
        </Col>

        <Col xs={24} lg={6} style={{ padding: "10px 5px" }}>
          <RangePicker
            value={[
              range?.from ? moment(range.from) : null,
              range?.to ? moment(range.to) : null,
            ]}
            style={{ width: "100%" }}
            placeholder={["from", "to"]}
            size="large"
            inputReadOnly
            onChange={(e) => handleSelectDate(e)}
          />
        </Col>

        <Col xs={24} lg={3} style={{ padding: "10px 5px" }}>
          <Select
            placeholder="Class name"
            value={classFilter}
            size="large"
            style={{ width: "100%" }}
            onChange={(e) => handleClass(e)}
          >
            <Option key="all" value="">
              -- All --
            </Option>
            <Option key="Private (Indoor)" value="Private (Indoor)">
              Private (Indoor)
            </Option>
            <Option key="Private (Outdoor)" value="Private (Outdoor)">
              Private (Outdoor)
            </Option>
            <Option key="Join (2-3pax)" value="Join (2-3pax)">
              Join (2-3pax)
            </Option>
            <Option key="Join (4-5pax)" value="Join (4-5pax)">
              Join (4-5pax)
            </Option>
          </Select>
        </Col>

        <Col xs={24} lg={3} style={{ padding: "10px 5px" }}>
          <Select
            placeholder="Detail"
            value={detailFilter}
            size="large"
            style={{ width: "100%" }}
            onChange={(e) => handleDetail(e)}
          >
            <Option key="all" value="">
              -- All --
            </Option>
            <Option key="Infant" value="Infant">
              Infant
            </Option>
            <Option key="Toddler" value="Toddler">
              Toddler
            </Option>
            <Option key="Kid" value="Kid">
              Kid
            </Option>
            <Option key="Adult" value="Adult">
              Adult
            </Option>
            <Option key="Autism" value="Autism">
              Autism
            </Option>
          </Select>
        </Col>

        <Col xs={24} lg={3} style={{ padding: "10px 5px" }}>
          <SelectInstructor
            title="Instructor"
            value={instructorFilter}
            setValue={setToInstructorFn}
          />
        </Col>

        <Col xs={24} lg={3} style={{ padding: "10px 5px" }}>
          <SelectLocation
            title="Location"
            value={locationFilter}
            setValue={setToLocationFn}
          />
        </Col>

        <Col xs={24} lg={3} style={{ padding: "10px 5px" }}>
          <Select
            placeholder="Payment"
            value={statusFilter}
            size="large"
            style={{ width: "100%" }}
            onChange={(e) => handleStatus(e)}
          >
            <Option key="all" value="">
              -- All --
            </Option>
            <Option value="paid">Paid</Option>
            <Option value="pending">Pending</Option>
          </Select>
        </Col>

        <Col xs={24} lg={3} style={{ padding: "10px 5px" }}>
          {range?.from ||
          range?.to ||
          classFilter ||
          instructorFilter ||
          detailFilter ||
          locationFilter ||
          statusFilter ? (
            <Button
              size="large"
              style={{ width: "100%" }}
              className="reset-btn"
              onClick={() => handleReset()}
            >
              Reset
            </Button>
          ) : null}
        </Col>

        {/* {
                        selectedRow.length > 0 ?
                        <Col xs={24} lg={3} style={{padding:'10px 5px'}}>
                            <Button
                                type="primary"
                                size="large"
                                style={{width:'100%'}}
                                loading={paidLoading}
                                onClick={()=> handleUpdate({status:'paid',ts_id:[...selectedRow]})}
                            >
                                SET PAID
                            </Button>
                        </Col>
                        :null
                    }

                    {
                        selectedRow.length > 0 ?
                        <Col xs={24} lg={3} style={{padding:'10px 5px'}}>
                            <Button
                                type="danger"
                                size="large"
                                style={{width:'100%'}}
                                loading={pendingLoading}
                                onClick={()=> handleUpdate({status:'pending',ts_id:[...selectedRow]})}
                            >
                                SET PENDING
                            </Button>
                        </Col>:null
                    } */}

        <Col xs={24} style={{ padding: 10 }}>
          <Table
            // showHeader={false}
            className="table-custom-list"
            columns={timesheetCol({ handleDelete, handleEdit, setOpenAdd })}
            dataSource={loading ? [] : tableDataWithNo}
            // rowSelection={rowSelection}
            rowClassName={(record) =>
              record?.ts_payment === "paid" ? "paid-row" : ""
            }
            rowKey={(record) => record?.ts_id}
            onChange={handleTableChange}
            pagination={{
              size: "small",
              total: dataList?.totalDoc,
              pageSizeOptions: ["100", "50", "30", "20"],
              pageSize: pageSize,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              },
            }}
            sticky
            scroll={{ x: 1200 }}
            loading={loading}
          />
        </Col>
        <Col xs={24} md={16} lg={16} xl={16}></Col>
        <Col
          xs={24}
          md={8}
          lg={8}
          xl={8}
          style={{ padding: 10, textAlign: "right" }}
        >
          <h3>Total : {currencyFormat(sumAmount)}</h3>
          <h3>Total paid : {currencyFormat(sumPaid)}</h3>
          <h3>Total pending : {currencyFormat(sumUnpaid)}</h3>
        </Col>
      </Row>
    </>
  );
}
