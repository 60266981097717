import {FormOutlined,DeleteOutlined, FileAddOutlined, TagsOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Space, Tag, Tooltip,Typography } from 'antd';
import moment from 'moment';
import React from 'react'
import { checkUpperCase, currencyFormat } from '../../../function/fn';

const {Paragraph,Text} = Typography

export default function timesheetCol({handleDelete,handleEdit,setOpenAdd}) {

    const content =(e)=>(
      <Paragraph style={{fontSize:16,width:300,textAlign:'justify'}}>
        {e}
      </Paragraph>
    )
    
    var array = [
        {
          title: 'N°',
          dataIndex: 'no',
          key: 'no',
          width:40,
          
        },
        {
          title: 'Instructor',
          dataIndex: 'name',
          key: 'name',
          width:80,
          render:(text,record)=>{

            let splitName = record?.name?.split(" ")

            return (
            <span>
                {splitName?.length > 1 ? splitName[1]:splitName[0]}
            </span>
            )
          }
          
        },
        // {
        //   title: 'ABA Number',
        //   dataIndex: 'aba_number',
        //   key: 'aba_number',
        //   width:90,
          
        // },
        {
          title: 'Date',
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: true,
          width:80,
          render:(text,record)=>(
            <span>
                {moment(record?.createdAt).format("DD/MM/YYYY")}
            </span>
          )
          
        },

        {
          title: 'Time',
          dataIndex: 'time',
          key: 'time',
          width:70,
          
        },

        {
          title: 'Class name',
          dataIndex: 'classType',
          key: 'classType',
          width:110,
          
        },

        {

          title: 'Location',
          dataIndex: 'location',
          key: 'location',
          width:70,
          render:(text,record)=>(
            <span>
                {checkUpperCase(record?.location)}
            </span>
          )
        },

        {
          title: 'Detail',
          dataIndex: 'forType',
          key: 'forType',
          width:70,
          
        },

        {
          title: 'Remark',
          dataIndex: 'ts_remark',
          key: 'ts_remark',
          width:100,
          render: (text,record)=>(
            <span style={{cursor:'pointer'}}  >
              
              <Popover placement="bottom"  content={()=>content(record?.ts_remark)} title={null} trigger="hover">
                <Text ellipsis >
                  {record?.ts_remark}
                </Text>
                
              </Popover>
              
            </span>
          )
          
        },

        {
          title: 'Amount',
          dataIndex: 'ts_price',
          key: 'ts_price',
          width:70,
          render: (text,record)=>(
            <span>
              
                <div className={record?.ts_payment === 'pending' ? "pending-badge":"success-badge"}>
                  { currencyFormat(record?.ts_price)}
                </div>
              
            </span>
          )
        },

        // {
        //   title: 'State',
        //   dataIndex: 'ts_payment',
        //   key: 'ts_payment',
        //   width:50,
        //   render: (text,record)=>(
        //     <span>
        //       
        //         { record?.ts_payment === 'pending' ? <div className="pending-badge">P</div>: <CheckCircleOutlined className="paid-badge" />}
        //       
        //     </span>
        //   )
        // },
        
        // {
        //   title: 'Tags',
        //   key: 'tags',
        //   dataIndex: 'tags',
        //   render: tags => (
        //     <>
        //       {tags.map(tag => {
        //         let color = tag.length > 5 ? 'geekblue' : 'green';
        //         if (tag === 'loser') {
        //           color = 'volcano';
        //         }
        //         return (
        //           <Tag color={color} key={tag}>
        //             {tag.toUpperCase()}
        //           </Tag>
        //         );
        //       })}
        //     </>
        //   ),
        // },
        {
          title: <Button className='custom-add-btn' onClick={()=> setOpenAdd(true)}><FileAddOutlined /></Button>,
          key: 'action',
          dataIndex:'action',
          fixed:'right',
          align:'center',
          width:70,
          render: (text, record) => (
            <Space size="middle">

              {
                record?.ts_payment === 'paid' ?
                <>
                  <Tag style={{width:'100%'}} icon={<TagsOutlined />} color="default">
                    PAID
                  </Tag>
                </>
                :
                <>
                  <Button size="small" onClick={()=> handleEdit(record)}><FormOutlined /></Button>

                  <Popconfirm
                    placement="topRight"
                    title={"Do you want to delete this timesheet?"}
                    onConfirm={()=> handleDelete(record?.ts_id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button size="small" type="danger"><DeleteOutlined /></Button>
                  </Popconfirm>
                </>
              }
              
            </Space>
          ),
        },
      ];

    return array;
}
