import { notification, Menu, Select, Alert } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { Detector } from 'react-detect-offline';
import { delete_cookie, getInstructorList, getLocationList, getStaffList } from "./fn";

const { Option } = Select

export const openSuccessNotification = (data) => {
  notification.success({
    message: `${data.title}`,
    description: data.message
  });
};

export const openErrorNotification = (data) => {
  notification.error({
    message: `${data.title}`,
    description: data.message
  });
};

export const openWarningNotification = (data) => {
  notification.warning({
    message: `${data.title}`,
    description: data.message
  });
};

export const menu = (
  <Menu>

    <Menu.Item key="0" onClick={() => delete_cookie("is_logged")}>
      <a rel="noopener noreferrer" href="../">
        Dashboard
      </a>
    </Menu.Item>
    <Menu.Item onClick={() => delete_cookie("is_logged")} key="1">
      <a rel="noopener noreferrer" href="../new.php">
        New
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="2">
      <a rel="noopener noreferrer" href="../studentlist.php">
        Swimmer
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="3">
      <a rel="noopener noreferrer" href="../student_de.php">
        Deactivated
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="4">
      <a rel="noopener noreferrer" href="../invoicelist.php">
        Invoice
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="5">
      <a rel="noopener noreferrer" href="../new-feedback.php">
        New feedback
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="6">
      <a rel="noopener noreferrer" href="../feeback.php">
        Feedback
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="7">
      <a rel="noopener noreferrer" href="../instructorlist.php">
        Instructor list
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="8">
      <a rel="noopener noreferrer" href="../userlist.php">
        User list
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="9">
      <a rel="noopener noreferrer" href="../courselist.php">
        Course list
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="10">
      <a rel="noopener noreferrer" href="../poollist.php">
        Pool list
      </a>
    </Menu.Item>

    <Menu.Item onClick={() => delete_cookie("is_logged")} key="9">
      <a rel="noopener noreferrer" href="../locationlist.php">
        Location list
      </a>
    </Menu.Item>

  </Menu>
);

export function SelectInstructor({ title, value, setValue, disabled, disableAddNew }) {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // setLoading(true)
    async function fetchData() {
      let data = await getInstructorList()
      setData(data)
      setLoading(false)
    }
    fetchData()
  }, [])

  const options = data?.map(d => <Option value={d?.id} key={d.id}>{d?.name}</Option>);

  return (
    <Select
      showSearch
      value={value}
      size="large"
      placeholder={title}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      onChange={(e) => setValue(e)}
      disabled={disabled}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.indexOf(input) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children.localeCompare(optionB.children)
      }
      loading={loading}
    >

      {!disableAddNew && <Option value="" key="all">-- All --</Option>}
      {options}
    </Select>
  )
}

export function SelectStaff({ title, value, setValue, disabled, disableAddNew }) {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // setLoading(true)
    async function fetchData() {
      let data = await getStaffList()
      setData(data)
      setLoading(false)
    }
    fetchData()
  }, [])

  const onSelectChange=(e)=>{
    let filterStaff = data?.filter(s => s?.id===e)

    setValue(filterStaff[0])
  }

  const options = data?.map(d => <Option value={d?.id} key={d.id}>{d?.name}</Option>);

  return (
    <Select
      showSearch
      value={value}
      size="large"
      placeholder={title}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      onChange={(e) => onSelectChange(e)}
      disabled={disabled}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.indexOf(input) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children.localeCompare(optionB.children)
      }
      loading={loading}
    >

      {!disableAddNew && <Option value="" key="all">-- All --</Option>}
      {options}
    </Select>
  )
}


export function SelectLocation({ title, value, setValue, disabled }) {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // setLoading(true)
    async function fetchData() {
      let data = await getLocationList()
      setData(data)
      setLoading(false)
    }
    fetchData()
  }, [])

  const options = data?.map(d => <Option value={d?.id} key={d.id}>{d?.location}</Option>);

  return (
    <Select
      showSearch
      value={value}
      size="large"
      placeholder={title}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      onChange={(e) => setValue(e)}
      disabled={disabled}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.indexOf(input) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children.localeCompare(optionB.children)
      }
      loading={loading}
    >
      <Option value="" key="all">-- All --</Option>
      {options}
    </Select>
  )
}

export function SelectLocationForPrint({ title, value, setValue, disabled }) {

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // setLoading(true)
    async function fetchData() {
      let data = await getLocationList()
      setData(data)
      setLoading(false)
    }
    fetchData()
  }, [])

  const options = data?.map(d => <Option value={d?.location} key={d.id}>{d?.location}</Option>);

  return (
    <Select
      showSearch
      value={value}
      size="medium"
      placeholder={title}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      onChange={(e) => setValue(e)}
      disabled={disabled}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.indexOf(input) >= 0
      }
      filterSort={(optionA, optionB) =>
        optionA.children.localeCompare(optionB.children)
      }
      loading={loading}
    >
      <Option value="" key="all">-- All --</Option>
      {options}
    </Select>
  )
}

export const CheckConnection = () => {

  const [status, setStatus] = useState(false)

  const handleSuccess = () => {
    // setStatus(false)
    openSuccessNotification({ title: 'Connected', message: 'You connection has been restored.' })
  }

  return (
    <Detector
      render={({ online }) => (
        <>
          {online ?
            status && handleSuccess()
            :
            <>
              {
                setStatus(true)
              }
              <Alert
                message="Disconnect"
                // description="This is an error message about copywriting."
                type="error"
                showIcon
              />
            </>
          }
        </>
      )}
    />

  )
}

export function SelectYear({ title, value, setValue, disabled }) {

  let date = '2020-01-01'
  let startYear = moment(date).format('YYYY')

  let lengthYear = Number(moment(new Date()).format('YYYY')) - Number(moment(date).format('YYYY'))

  let options = [<Option value={startYear} key={startYear}>{startYear}</Option>]
  
  for(let i=1;i<=lengthYear;i++){
    let plusYear = moment(date).add(i,'years').format('YYYY')
    options.push(<Option value={plusYear} key={plusYear}>{plusYear}</Option>)
  } 

  return (
    <Select
      // showSearch
      value={value}
      size="large"
      placeholder={title}
      style={{ width: '100%' }}
      // defaultActiveFirstOption={false}
      // filterOption={false}
      onChange={(e) => setValue(e)}
      disabled={disabled}
      optionFilterProp="children"
      // filterOption={(input, option) =>
      //   option.children.indexOf(input) >= 0
      // }
      // filterSort={(optionA, optionB) =>
      //   optionA.children.localeCompare(optionB.children)
      // }
    >
      {/* <Option value="" key="all">-- All --</Option> */}
      {options}
    </Select>
  )
}
