import { Button, Col, DatePicker, Drawer, Form, Input, InputNumber, Radio, Row, Select, Switch } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { time, updateTimesheetById } from '../../../function/fn'

const { Option } = Select
const {TextArea} =Input

export default function EditTimesheet({ data, open, setOpen,setSuccess }) {

    const [form] = Form.useForm()
    const [isMobile, setIsMobile] = useState(false)
    const [loading,setLoading] = useState(false)

    const [forType,setForType] = useState("")

    const [paid,setPaid] = useState(false)

    useEffect(()=>{
        if(data){
            form.setFieldsValue({
                ...data,
                createdAt: moment(data?.createdAt)
            })
            setPaid(data?.ts_payment === "paid")
        }
    },[data])

    const handleResize = () => {
        if (window.innerWidth <= 960) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        handleResize()
    }, [])

    window.addEventListener('resize', handleResize)

    const onFinish = (values) => {
        setLoading(true)
        const newUpdate ={
            ...values,
            ts_id:data?.ts_id,
            createdAt: moment(values?.createdAt).format("YYYY-MM-DD").toString(),
            ts_price: parseFloat(values?.ts_price),
            // ts_payment: paid ? "paid":"pending",
        }
        
        async function updateData(){
            let success = await updateTimesheetById(newUpdate)
            if(success){
                setSuccess(true)
                setLoading(false)
                setOpen(false)
                form.resetFields()
            }else{
                setLoading(false)
            }
            
        }
        updateData()
    }

    const onFinishFailed = (failed) => {
        console.log("Failedd", failed)
    }

    const onForTypeCheck =(e)=>{
        setForType(e)
    }

    return (
        <>
            <Drawer
                title="UPDATE TIMESHEET"
                placement="right"
                width={isMobile ? '100%' : 736}
                onClose={() => setOpen(false)}
                visible={open}
            // extra={
            // <Space>
            //     <Button onClick={onClose}>Cancel</Button>
            //     <Button type="primary" onClick={onClose}>
            //     OK
            //     </Button>
            // </Space>
            // }
            >
                <Form
                    name="editTimesheet"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    form={form}
                    layout="vertical"
                >
                    <Row gutter={10}>
                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                name="name"
                                label="Intructor"
                                rules={[{ required: true, message: 'Fill input!' }]}
                            >
                                <Input disabled placeholder="instructor" size="large" />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                name="classType"
                                label="Class name"
                                rules={[{ required: true, message: 'Fill input!' }]}
                            >
                                <Select placeholder="class type" size="large">
                                    <Option key="Private (Indoor)" value="Private (Indoor)">Private (Indoor)</Option>
                                    <Option key="Private (Outdoor)" value="Private (Outdoor)">Private (Outdoor)</Option>
                                    <Option key="Join (2-3pax)" value="Join (2-3pax)">Join (2-3pax)</Option>
                                    <Option key="Join (4-5pax)" value="Join (4-5pax)">Join (4-5pax)</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                name="createdAt"
                                label="Date"
                                rules={[{ required: true, message: 'Fill input!' }]}
                            >
                                <DatePicker disabled placeholder="date" size="large" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                name="time"
                                label="Time"
                                rules={[{ required: true, message: 'Fill input!' }]}
                            >
                                <Select
                                    // showSearch
                                    size="large"
                                    placeholder="Time"
                                    style={{ width: '100%' }}
                                    defaultActiveFirstOption={false}

                                >
 
                                    {
                                      time()
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                                name="forType"
                                label="Detail"
                                rules={[{ required: true, message: 'Fill input!' }]}
                            >
                                <Radio.Group onChange={(e)=>onForTypeCheck(e)} value={forType} style={{width:'100%'}}>
                                    <Row style={{width:'100%'}}>
                                        <Col xs={12} lg={5} style={{padding:10}}>
                                            <Radio value="Infant">Infant</Radio>
                                        </Col>
                                        <Col xs={12} lg={5} style={{padding:10}}>
                                            <Radio value="Toddler" >Toddler</Radio>
                                        </Col>
                                        <Col xs={12} lg={5} style={{padding:10}}>
                                            <Radio value="Kid">Kid</Radio>
                                        </Col>
                                        <Col xs={12} lg={5} style={{padding:10}}>
                                            <Radio value="Adult" >Adult</Radio>
                                        </Col>
                                        <Col xs={12} lg={4} style={{padding:10}}>
                                            <Radio value="Autism" >Autism</Radio>
                                        </Col>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>


                        <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                name="ts_price"
                                label="Amount"
                                rules={[{ required: true, message: 'Fill input!' }]}
                            >
                                <InputNumber placeholder="price" size="large" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>

                        {/* <Col xs={24} sm={24} md={12} lg={12}>
                            <Form.Item
                                name="ts_payment"
                                label="Payment"
                               
                            >
                                <Switch checkedChildren="PAID" size="large" unCheckedChildren="PENDING" checked={paid} onChange={(e)=> setPaid(e)} />
                            </Form.Item>
                        </Col> */}

                        <Col xs={24} sm={24} md={24} lg={24}>
                            <Form.Item
                                name="ts_remark"
                                label="Remark"
                                // rules={[{ required: true, message: 'Fill input!' }]}
                            >
                                <TextArea rows={8} size="large" placeholder="remark..." allowClear />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item>
                                <Button type="primary" loading={loading} size="large" htmlType="submit">SUBMIT CHANGES</Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Drawer>
        </>
    )
}
