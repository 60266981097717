import { AreaChartOutlined } from '@ant-design/icons';
import { Button, Col, Row, Table, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import AddReport from '../component/report/modal/addReport';
import AddReportStaff from '../component/report/modal/addReportStaff';
import FilterSummary from '../component/report/modal/filterSummary';
import InvoiceToPrint from '../component/report/modal/InvoiceToPrint';
import VoidPayment from '../component/report/modal/voidPayment';
import reportCol from '../component/report/tableColumn/reportCol';
import { deletePaymentTimesheetById, getCookie, getReportWithPagination, getSummaryReportByMonth, getTimesheetByPayment, updatePaymentTimesheetVoid } from '../function/fn';
import { CheckConnection } from '../function/own-comp';

const { Option } = Select

export default function Report() {

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(100)

    const [loading, setLoading] = useState(false)

    // const [updateData,setUpdateData] = useState(null)

    const [dataList, setDataList] = useState([])
    const [sumAmount, setSumAmount] = useState(0)

    const [success, setSuccess] = useState(false)

    const [openAdd, setOpenAdd] = useState(false)
    const [openAddStaff, setOpenAddStaff] = useState(false)
    const [openPrint, setOpenPrint] = useState(false)
    const [openSummary, setOpenSummary] = useState(false)

    //filter
    const [voidFilter, setVoidFilter] = useState(null)
    const [printData, setPrintData] = useState(null)

    //void section
    const [openVoid, setOpenVoid] = useState(false)
    const [selectData, setSelectData] = useState(null)

    const [timesheetByPayment, setTimesheetByPayment] = useState(null)
    const [paymentLoading, setPaymentLoading] = useState(false)

    async function fetchData() {
        let data = await getReportWithPagination(page, pageSize,voidFilter)
        setDataList(data)
        setSumAmount(data?.sumAmount)
        setLoading(false)
        setSuccess(false)

    }

    async function getPrintTimesheet(e) {
        let data = await getTimesheetByPayment({ pt_id: e })
        setTimesheetByPayment(data)
        setPaymentLoading(false)
    }

    useEffect(() => {
        setLoading(true)
        fetchData()
    }, [page, pageSize, success, voidFilter])

    // useEffect(()=>{

    //     if(printData!==null && !openPrint){
    //         setPrintData(null)
    //     }

    // },[openPrint])

    useEffect(() => {

        if (printData) {
            setPaymentLoading(true)
            getPrintTimesheet(printData?.pt_id)
        }

    }, [printData])

    async function updateData(e) {

        let success = await updatePaymentTimesheetVoid(e)
        if (success) {
            setSuccess(true)
            setLoading(false)
        } else {
            setLoading(false)
        }

    }

    const handleDelete = (e) => {
        setLoading(true)
        async function deleteData() {
            if (await deletePaymentTimesheetById(e)) {
                setSuccess(true)
            }
        }
        deleteData()
    }

    const handlePrint = (e) => {
        setPrintData(e)
        setOpenPrint(true)
    }

    const handleVoid = (e) => {
        setSelectData(e)
        setOpenVoid(true)
    }

    const handleVoidFilter = (e) => {
        setVoidFilter(e)
    }

    const handleReset =()=>{
        setVoidFilter(null)
    }

    let tableDataWithNo = []

    dataList?.data?.map((record, index) => {

        let pageAdd = page > 1 ? ((page * pageSize) - pageSize) + 1 : 1;

        let data = { ...record, no: (dataList?.totalDoc - (pageAdd + index)) + 1 }
        tableDataWithNo.push(data)

    })

    return (
        <>
            <AddReport setOpen={setOpenAdd} open={openAdd} setSuccess={setSuccess} />
            <AddReportStaff setOpen={setOpenAddStaff} open={openAddStaff} setSuccess={setSuccess} />
            <InvoiceToPrint setOpen={setOpenPrint} open={openPrint} data={printData} timesheet={timesheetByPayment?.data} paymentLoading={paymentLoading} />
            <FilterSummary open={openSummary} setOpen={setOpenSummary} />
            <VoidPayment open={openVoid} setOpen={setOpenVoid} setSuccess={setSuccess} selectData={selectData} />

            <Row>
                <Col xs={24} lg={24} style={{ padding: 10 }}>
                    <h2>PAYMENT </h2>
                </Col>

                {/* <Col xs={24} lg={5} style={{ padding: 10 }}>
                    <Button size='large' onClick={() => setOpenSummary(true)} style={{width:'100%'}} ><AreaChartOutlined style={{ fontSize: 15 }} />SALARY REPORT</Button>

                </Col> */}

                <Col xs={24} lg={3} style={{ padding: '10px 5px' }}>
                    <Select placeholder="Void?" value={voidFilter} size="large" style={{ width: '100%' }} onChange={(e) => handleVoidFilter(e)}>
                        <Option key="all" value="">-- All --</Option>
                        <Option key="1" value="1">Voided</Option>
                        <Option key="0" value="0">Unvoided</Option>

                    </Select>
                </Col>

                <Col xs={24} lg={3} style={{padding:'10px 5px'}}>
                    { voidFilter ?
                    <Button size="large" style={{width:'100%'}} className="reset-btn" onClick={()=>handleReset()}>Reset</Button>
                    :null}
                </Col>

                <Col xs={24} style={{ padding: 10 }}>
                    <Table

                        className="table-custom-list"
                        columns={reportCol({ handleDelete, handleVoid, handlePrint, setOpenAdd,setOpenAddStaff })}
                        dataSource={loading ? [] : tableDataWithNo}
                        rowKey={record => record?.pt_id}
                        rowClassName={record => parseInt(record?.void) > 0 ? 'void-row' : parseInt(record?.is_staff)>0 ? 'is-staff-row':""}
                        // onChange={handleTableChange}
                        pagination={{
                            size: 'small',
                            total: dataList?.totalDoc,
                            pageSizeOptions: ["10", "20", '30', '50', '100', '200'],
                            // showSizeChanger: true,
                            pageSize: pageSize,
                            // current: peopleData?.paginator?.currentPage,
                            onChange: ((page, pageSize) => { setPage(page); setPageSize(pageSize) })
                        }}
                        sticky
                        scroll={{ x: 1200 }}
                        loading={loading}

                    />
                </Col>
            </Row>
        </>
    )
}
