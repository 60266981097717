import CryptoJS from "crypto-js";

export const secretPass = "XkhZG4fW2t2W@vc2023";

export const decryptString = (text) => {
  const bytes = CryptoJS.AES.decrypt(text, secretPass);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const encryptString = (text) => {
  return CryptoJS.AES.encrypt(text, secretPass).toString();
};
