import { Button, Col, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { SelectYear } from "../../../function/own-comp";
import SummaryReportPrint from "./summaryReportPrint";

const { Option } = Select;

export default function FilterSummary({
  open,
  setOpen,
  setSuccess,
  isForPrint,
}) {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  const [openPrint, setOpenPrint] = useState(false);

//   useEffect(() => {
//     if (!open) {
//       setMonth(null);
//       setYear(null);
//     }
//   }, [open]);

  const setToYearFn = (e) => {
    setYear(e);
  };

  const handleGetPayment = () => {
    if (month && year) {
      setOpen(false);

      setOpenPrint(true);
    }
  };

  return (
    <>
      <SummaryReportPrint
        open={openPrint}
        setOpen={setOpenPrint}
        month={month}
        year={year}
        setSuccess={setSuccess}
        isForPrint={isForPrint}
        setMonth={setMonth}
        setYear={setYear}
      />
      <Modal
        title="SELECT MONTH & YEAR"
        visible={open}
        width={600}
        // onOk={()=> setOpen(false)}
        onCancel={() => setOpen(false)}
        footer={[
          <Button onClick={() => handleGetPayment()} type="primary">
            GET PAYMENT
          </Button>,
        ]}
      >
        <Row>
          <Col xs={24} xl={12} style={{ padding: 10 }}>
            <Select
              size="large"
              style={{ width: "100%" }}
              value={month}
              onChange={(e) => setMonth(e)}
              placeholder="pay for month"
            >
              <Option value="January">January</Option>
              <Option value="February">February</Option>
              <Option value="March">March</Option>
              <Option value="April">April</Option>
              <Option value="May">May</Option>
              <Option value="June">June</Option>
              <Option value="July">July</Option>
              <Option value="August">August</Option>
              <Option value="September">September</Option>
              <Option value="October">October</Option>
              <Option value="November">November</Option>
              <Option value="December">December</Option>
            </Select>
          </Col>
          <Col xs={24} xl={12} style={{ padding: 10 }}>
            <SelectYear setValue={setToYearFn} value={year} title={"year"} />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
