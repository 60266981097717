import React, { useEffect, useState } from 'react'
import { Button, Col, Image, Layout ,Menu, Row} from 'antd';
import Logo from '../assets/logo-ssc.png'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { CheckConnection } from '../function/own-comp';

const { Header } = Layout;

export default function Navbar({collapsed,setCollapsed}) {

    return (
        <>
        
        <Header className="site-layout-background" style={{ position: 'fixed', zIndex: 999, width: '100%',padding: '0px 20px' }}>
            <Row>
                <Col xs={2}>
                    {collapsed ? <MenuUnfoldOutlined className="trigger" onClick={()=>setCollapsed(false)} /> : <MenuFoldOutlined className="trigger" onClick={()=>setCollapsed(true)} />}
                </Col>
                <Col xs={4} style={{paddingTop:10}}>
                    <CheckConnection />
                </Col>
            </Row>
        </Header>
        </>
    )
}