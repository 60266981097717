import {
  FormOutlined,
  DeleteOutlined,
  FileAddOutlined,
  DisconnectOutlined,
  PrinterFilled,
  MinusCircleOutlined,
  DollarOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Menu,
  Popconfirm,
  Popover,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import React from "react";
import { checkUpperCase, currencyFormat } from "../../../function/fn";

const { Paragraph, Text } = Typography;

export default function reportCol({
  handleDelete,
  handleVoid,
  handlePrint,
  setOpenAdd,
  setOpenAddStaff,
}) {
  const content = (e) => (
    <Paragraph style={{ fontSize: 16, width: 300, textAlign: "justify" }}>
      {e}
    </Paragraph>
  );

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => setOpenAdd(true)}>
        Instructor
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setOpenAddStaff(true)}>
        Staff
      </Menu.Item>
    </Menu>
  );

  var array = [
    {
      title: "N°",
      dataIndex: "no",
      key: "no",
      width: 40,
    },
    {
      title: "Instructor",
      dataIndex: "name",
      key: "name",
      width: 80,
      render: (text, record) => {
        let splitName = record?.name?.split(" ");

        return (
          <span>{splitName?.length > 1 ? splitName[1] : splitName[0]}</span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      // sorter: true,
      width: 130,
      render: (text, record) => (
        <span>
          {moment(record?.startDate).format("DD/MM/YYYY")} -{" "}
          {moment(record?.endDate).format("DD/MM/YYYY")}
        </span>
      ),
    },

    {
      title: "Total hours",
      dataIndex: "totalHours",
      key: "totalHours",
      width: 70,

      render: (text, record) => (
        <span>
          {parseFloat(record?.is_staff) > 0
            ? "Staff"
            : parseFloat(record?.totalHours)?.toFixed(2)}
        </span>
      ),
    },

    {
      title: "Total amount",
      dataIndex: "totalAmount",
      key: "totalAmount",
      width: 70,
      render: (text, record) => (
        <span>
          {/* <div className={record?.ts_payment === 'pending' ? "pending-badge":"success-badge"}> */}
          {currencyFormat(record?.totalAmount)}
          {/* </div> */}
        </span>
      ),
    },

    {
      title: "Remark",
      dataIndex: "remark",
      key: "remark",
      width: 100,
      render: (text, record) => (
        <span style={{ cursor: "pointer" }}>
          <Popover
            placement="bottom"
            content={() => content(record?.remark)}
            title={null}
            trigger="hover"
          >
            <Text ellipsis>{record?.remark}</Text>
          </Popover>
        </span>
      ),
    },

    {
      title: "Remark2",
      dataIndex: "voidRemark",
      key: "voidRemark",
      width: 100,
      render: (text, record) => (
        <span style={{ cursor: "pointer" }}>
          <Popover
            placement="bottom"
            content={() => content(record?.voidRemark)}
            title={null}
            trigger="hover"
          >
            <Text ellipsis>{record?.voidRemark}</Text>
          </Popover>
        </span>
      ),
    },

    {
      title: (
        <Dropdown overlay={menu} placement="bottomRight">
          <Button>
            {/* <DollarOutlined /> */}
            <EllipsisOutlined className="dot-menu" />
          </Button>
        </Dropdown>
      ),

      key: "action",
      dataIndex: "action",
      fixed: "right",
      align: "center",
      width: 70,
      render: (text, record) => (
        <Space size="middle">
          {parseInt(record?.void) <= 0 ? (
            <>
              <Button size="small" onClick={() => handlePrint(record)}>
                <PrinterFilled />
              </Button>

              <Button size="small" onClick={() => handleVoid(record)}>
                <DisconnectOutlined />
              </Button>
            </>
          ) : (
            <Tag
              style={{ width: "100%", cursor: "pointer" }}
              icon={<MinusCircleOutlined />}
              color="default"
            >
              VOIDED
            </Tag>
          )}

          {/* <Popconfirm
                title={"Do you want to delete this timesheet?"}
                onConfirm={()=> handleDelete(record?.pt_id)}
                okText="Yes"
                cancelText="No"
              >
              <Button size="small" type="danger"><DeleteOutlined /></Button>
              </Popconfirm> */}
        </Space>
      ),
    },
  ];

  return array;
}
