import { Button, Modal } from 'antd'
import React, { useRef, useState } from 'react'
import ReactToPrint from 'react-to-print';
import PrintInvoice from '../print/PrintInvoice';

export default function InvoiceToPrint({open,setOpen,data,timesheet,paymentLoading}) {

    const componentRef = useRef();

    const [show,setShow] = useState(true)

    return (
        <Modal
            title="PAY SLIP"
            visible={open}
            width={600}
            // onOk={()=> setOpen(false)}
            onCancel={()=> setOpen(false)}
            footer={[
                <Button onClick={()=> setShow(!show)}>{show ? 'Hide':'Show'} details</Button>,
                <ReactToPrint
                    trigger={() => <Button type='primary'>Print</Button>}
                    content={() => componentRef.current }
                />
                
            ]}
        >
            <PrintInvoice ref={componentRef} data={data} show={show} timesheet={timesheet} paymentLoading={paymentLoading} />
        </Modal>
    )
}
