import { Button, Col, Modal, Row, Select } from "antd";
import React, { useRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import { useRecoilValue } from "recoil";
import {
  createMondlyPaid,
  getCookie,
  getMonthFromString,
  getSummaryReportByMonth,
  getSumReportByLocation,
} from "../../../function/fn";
import {
  openWarningNotification,
  SelectLocation,
  SelectLocationForPrint,
} from "../../../function/own-comp";
import { userLoginAtom } from "../../../recoils";
import PrintReport from "../print/PrintReport";

export default function SummaryReportPrint({
  open,
  setOpen,
  month,
  year,
  setSuccess,
  isForPrint,
  setMonth,
  setYear,
}) {
  const componentRef = useRef();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const [location, setLocation] = useState("");
  const [isStaff, setIsStaff] = useState(null);

  const [saveLoading, setSaveLoading] = useState(false);

  const [dataFilter, setDataFilter] = useState(null);

  const userLogin = useRecoilValue(userLoginAtom);

  async function getData() {
    let data = await getSummaryReportByMonth({ month: month, year: year });
    // console.log(data,'data')
    setData(data);
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    getData();
  }, [open]);

  const setSaveFn = async () => {
    if (data?.data?.length <= 0) {
      openWarningNotification({
        title: "Warning!",
        message: "There's no payment.",
      });
      return;
    }

    setSaveLoading(true);
    let success = await createMondlyPaid({
      month: month,
      year: year,
      totalPaid: data?.sumPaid,
      createdBy: userLogin?.id,
      paymentTimesheet: data?.data?.map((e) => e?.pt_id),
    });
    if (success) {
      setSaveLoading(false);
      setSuccess(true);
    } else {
      setSaveLoading(false);
      // setSuccess(true)
    }
  };

  useEffect(() => {
    if (!open) {
    }
  }, [open]);

  useEffect(() => {
    setLoading(true);
    if (location !== "" || isStaff) {
      let dataByLocation =
        location !== ""
          ? data?.data?.filter((e) => e?.location === location)
          : data?.data;

      if (isStaff) {
        dataByLocation = dataByLocation?.map((e) => {
          let instructors = e?.instructors?.filter(
            (load) => load?.is_staff === isStaff
          );
          return {
            ...e,
            instructors,
          };
        });
      }

      let sumPaid = getSumReportByLocation(
        dataByLocation?.map(e => e?.instructors)?.flat(),
        location
      );
      setDataFilter({
        data: dataByLocation,
        sumPaid: sumPaid,
      });
      setLoading(false);
    } else {
      setLoading(false);
      setDataFilter(data);
    }
  }, [location, isStaff]);

  const handleSelectLocation = (e) => {
    setLocation(e);
  };

  console.log(dataFilter)

  return (
    <Modal
      title="SUMMARY REPORT PAYMENT"
      visible={open}
      width={600}
      onCancel={() => {
        setOpen(false);
        setIsStaff(null);
        setLocation("");
        setMonth(null);
        setYear(null);
      }}
      footer={[
        <Button onClick={() => setSaveFn()} loading={saveLoading}>
          Save
        </Button>,
        <ReactToPrint
          trigger={() => <Button type="primary">Print</Button>}
          content={() => componentRef.current}
          onAfterPrint={() => {
            setIsStaff(null);
            setLocation("");
            setMonth(null);
            setYear(null);
          }}
        />,
      ]}
    >
      <Row gutter={10}>
        <Col xs={24} lg={8}></Col>
        <Col xs={24} lg={8}>
          <Select
            style={{ width: "100%" }}
            value={isStaff}
            onChange={(e) => setIsStaff(e)}
          >
            <Select.Option value="0">Instructor</Select.Option>
            <Select.Option value="1">Staff</Select.Option>
          </Select>
        </Col>
        <Col xs={24} lg={8}>
          <SelectLocationForPrint
            value={location}
            setValue={handleSelectLocation}
          />
        </Col>
      </Row>
      <PrintReport
        ref={componentRef}
        data={location !== "" || isStaff ? dataFilter : data}
        location={location}
        loading={loading}
        dateSelect={`${year}-${String(getMonthFromString(month)).padStart(
          2,
          "0"
        )}-01`}
      />
    </Modal>
  );
}
