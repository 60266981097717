import "./App.css";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { Layout } from "antd";
import Navbar from "./dynamic/navbar";
import Timesheet from "./page/Timesheet";
import { useEffect, useMemo, useState } from "react";
import MenuSide from "./dynamic/menu";
import Report from "./page/Report";
import MonthlyPaid from "./page/MonthlyPaid";
import { useRecoilState } from "recoil";
import { userLoginAtom } from "./recoils";
import { decryptString } from "./function/encryption";

const { Content } = Layout;

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  // const [isLogin, setIsLogin] = useState(true);

  const [collapsed, setCollapsed] = useState(false);

  const history = useHistory();

  const [userLogin, setUserLogin] = useRecoilState(userLoginAtom);

  // get username & position from sscms
  const query = useQuery();
  const user_auth = query.get("user_auth");

  const handleResize = () => {
    if (window.innerWidth <= 1584) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    if (user_auth) {
      const decrypted = decryptString(user_auth);
      setUserLogin(JSON.parse(decrypted) || {});
      history.replace("/");
    }
  }, []);

  // useEffect(() => {
  //   // async function checkUser(){
  //   //     let data = await isUser()
  //   //     console.log(data)
  //   //     if(data && data?.position !== 'notuser'){
  //   //       setIsLogin(true)
  //   //     }else{
  //   //       window.location.replace('../logout.php')
  //   //       setIsLogin(false)
  //   //     }
  //   // }
  //   // setTimeout(checkUser(),2000)
  //   // checkUser()
  //   // let data = JSON.parse(getCookie("is_logged") || {})

  //   if (getCookie("is_logged") === "is_user_ssc") {
  //     setIsLogin(true);
  //   } else {
  //     setIsLogin(true);
  //   }
  // }, []);

  return userLogin?.id && userLogin?.username && (userLogin?.post !== "staff") ? (
    <div className="App">
      <Layout style={{ minHeight: "100vh" }}>
        <MenuSide collapsed={collapsed} />
        <Layout className="site-layout" style={{ backgroundColor: "#08394e" }}>
          <Navbar collapsed={collapsed} setCollapsed={setCollapsed} />

          <center>
            <Content
              className="site-layout-background"
              style={{
                margin: 20,
                marginTop: 85,
                padding: 20,
                textAlign: "left",
              }}
            >
              <Switch>
                <Route exact path="/">
                  <Timesheet />
                </Route>
                <Route path="/payment">
                  <Report />
                </Route>

                <Route path="/report">
                  <MonthlyPaid />
                </Route>
              </Switch>
            </Content>
          </center>
        </Layout>
      </Layout>
    </div>
  ) : (
    <h3>Not login</h3>
  );
}

export default App;
