export default function locationOnlyColumn() {

    var array = [
        {
            title: null,
            dataIndex: 'location',
            key: 'location',
            //   width:40,
            render: (text, record) => (
                <span>
                    <b>{record?.location}</b>
                </span>
            )
        },
    ];

    return array;
}
