import { Button, Col, Drawer, Form, Input, Modal, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  createPayment,
  getCookie,
  updatePaymentTimesheetVoid,
} from "../../../function/fn";
import { userLoginAtom } from "../../../recoils";

const { TextArea } = Input;

export default function VoidPayment({ open, setOpen, setSuccess, selectData }) {
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);
  const userLogin = useRecoilValue(userLoginAtom);

  const handleResize = () => {
    // 960
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  const onFinish = (values) => {
    setLoading(true);

    async function updateData(e) {
      let success = await updatePaymentTimesheetVoid(e);
      if (success) {
        setSuccess(true);
        setLoading(false);
        setOpen(false);
      } else {
        setLoading(false);
      }
    }

    updateData({
      pt_id: selectData?.pt_id,
      mondlyId: selectData?.mondlyId,
      voidRemark: values?.voidRemark,
      voidBy: userLogin?.id,
    });
  };

  const onFinishFailed = (failed) => {
    console.log("Failedd", failed);
  };

  return (
    <>
      <Modal
        title="VOID PAYMENT"
        // width={isMobile ? "100%" : 736}
        // onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        visible={open}
        footer={null}
      >
        <h3>
          <b>{selectData?.name}: </b>
          {moment(selectData?.startDate).format("DD/MM/YYYY")} -{" "}
          {moment(selectData?.endDate).format("DD/MM/YYYY")}
        </h3>
        <Form
          name="VoidPayment"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item
                name="voidRemark"
                label="Remark"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <TextArea
                  rows={5}
                  size="large"
                  placeholder="remark..."
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col
              span={24}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  size="large"
                  htmlType="submit"
                >
                  SET VOID
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
