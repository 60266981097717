import { LoadingOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { currencyFormat, pad } from "../../../function/fn";
import Logo from "../../../assets/logo-ssc.png";
import reportAddTimesheet from "../tableColumn/reportAddTimesheet";
import reportPrintTimesheet from "../tableColumn/reportPrintTimesheet";

export default class PrintInvoice extends Component {
  render() {
    const data = this.props?.data;

    const show = this.props?.show;
    const loading = this.props?.paymentLoading;

    const timesheet = this.props?.timesheet;

    let splitName = data?.name?.split(" ");

    if (loading) {
      return (
        <center>
          <LoadingOutlined style={{ fontSize: 40, color: "#29aae1" }} />
        </center>
      );
    }

    return (
      <>
        <Row>
          <Col
            xs={24}
            style={{
              padding: "10px 20px",
              height: show ? "auto" : 793,
              position: "relative",
            }}
          >
            {/* <img src={Logo}  style={{ width: 150 }} /> */}
            <table style={{ width: "100%", marginBottom: 10 }}>
              <tbody>
                <tr>
                  <td style={{ width: "30%" }}>
                    <img src={Logo} style={{ width: 90 }} />
                  </td>
                  <td style={{ width: "30%" }}></td>
                  <td
                    style={{
                      width: "40%",
                      position: "relative",
                      textAlign: "center",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    <b className="salary-text">SALARY PAYMENT</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <table style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td style={{ width: "40%" }}>
                    <table className="table-bill-to">
                      <thead>
                        <tr>
                          <th colSpan={2}>BILL TO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ width: 60 }}>
                            <b>ID</b>
                          </td>
                          <td>
                            : {moment(data?.createdAt).format("YYYY")}
                            {String(data?.id).padStart(5, "0")}
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: 60 }}>
                            <b>Name</b>
                          </td>
                          <td>: {data?.name}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>ABA #</b>
                          </td>
                          <td>: {data?.aba_number}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Remark</b>
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </td>

                  <td style={{ width: "15%" }}></td>

                  <td style={{ width: "45%" }}>
                    <table className="table-bill-to">
                      <thead>
                        <tr>
                          <th>Start date</th>
                          <th>End date</th>
                        </tr>
                        <tr>
                          <td>
                            <center>
                              {moment(data?.startDate).format("DD/MM/YYYY")}
                            </center>
                          </td>
                          <td>
                            <center>
                              {moment(data?.endDate).format("DD/MM/YYYY")}
                            </center>
                          </td>
                        </tr>
                      </thead>
                    </table>
                    <table className="table-bill-to">
                      <tbody>
                        <tr>
                          <td style={{ width: 90 }}>
                            <b>Payment for</b>
                          </td>
                          <td>: {data?.payForMonth}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Location</b>
                          </td>
                          <td>: {data?.location}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Contact</b>
                          </td>
                          <td>: {data?.phone_number}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table-bill-to">
              <tbody>
                <tr>
                  <td>{data?.remark}</td>
                </tr>
              </tbody>
            </table>
            <br />
            <table className="table-description">
              <tbody>
                <tr>
                  <th style={{ width: "10%" }}>N°</th>
                  <th style={{ width: "60%" }}>Description</th>
                  <th style={{ width: "30%" }}>AMOUNT</th>
                </tr>
                <tr>
                  <td>1</td>
                  <td>
                    {data?.name} - {parseFloat(data?.totalHours)}Hours
                  </td>
                  <td>{currencyFormat(data?.totalAmount)}</td>
                </tr>
              </tbody>
            </table>
            <br />

            {show && (
              <>
                <div>Details</div>
                <Table
                  // showHeader={false}
                  className="table-print"
                  columns={reportPrintTimesheet()}
                  dataSource={timesheet}
                  rowKey={(record) => record?.ts_id}
                  pagination={false}
                  sticky
                  loading={loading}
                  // scroll={{ x: 500 }}
                />
                <br />
              </>
            )}

            <Row>
              <Col xs={8} className="col-foot">
                <div>
                  <b>Received by</b>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div>Date:</div>
                </div>
              </Col>

              <Col xs={8} className="col-foot">
                <div>
                  <b>Checked by</b>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div>Date:</div>
                </div>
              </Col>

              <Col xs={8} className="col-foot">
                <div>
                  <b>Prepared by</b>
                  <br />
                  <br />
                  <br />
                  <br />
                  <div>Date:</div>
                </div>
              </Col>
            </Row>

            <div
              className={show ? "footer-container-unfixed" : "footer-container"}
            >
              <Row>
                <Col xs={24} style={{ paddingTop: 5 }}>
                  <div className="col-foot">
                    012 797 085 | 015 382 803 | srsurvivalswim@gmail.com |
                    survivalswimclub.com
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </>
    );
  }
}
