import {FormOutlined,DeleteOutlined, FileAddOutlined, DisconnectOutlined,PrinterFilled, MinusCircleOutlined,DollarOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Popover, Space, Tag, Tooltip,Typography } from 'antd';
import moment from 'moment';
import React from 'react'
import { checkUpperCase, currencyFormat } from '../../../function/fn';

const {Paragraph,Text} = Typography

export default function monthlyPaidCol({handlePrint}) {

    const content =(e)=>(
      <Paragraph style={{fontSize:16,width:300,textAlign:'justify'}}>
        {e}
      </Paragraph>
    )
    
    var array = [
        {
          title: 'N°',
          dataIndex: 'no',
          key: 'no',
          width:40,
          
        },
        {
          title: 'Month',
          dataIndex: 'month',
          key: 'month',
          width:80,
          
        },
        {
          title: 'Year',
          dataIndex: 'year',
          key: 'year',
          // sorter: true,
          width:130,
        //   render:(text,record)=>(
        //     <span>
        //         {moment(record?.startDate).format("DD/MM/YYYY")} - {moment(record?.endDate).format("DD/MM/YYYY")}
        //     </span>
        //   )
          
        },

        {
          title: 'Total',
          dataIndex: 'totalPaid',
          key: 'totalPaid',
          width:70,

          render:(text,record)=>(
            <span>
                {parseFloat(record?.totalPaid)?.toFixed(2)}
            </span>
          )
          
        },

        // {
        //   title: 'Total amount',
        //   dataIndex: 'totalAmount',
        //   key: 'totalAmount',
        //   width:70,
        //   render: (text,record)=>(
        //     <span>
              
        //         {/* <div className={record?.ts_payment === 'pending' ? "pending-badge":"success-badge"}> */}
        //           { currencyFormat(record?.totalAmount)}
        //         {/* </div> */}
              
        //     </span>
        //   )
        // },

        // {
        //   title: 'Remark',
        //   dataIndex: 'remark',
        //   key: 'remark',
        //   width:100,
        //   render: (text,record)=>(
        //     <span style={{cursor:'pointer'}}  >
              
        //       <Popover placement="bottom"  content={()=>content(record?.remark)} title={null} trigger="hover">
        //         <Text ellipsis >
        //           {record?.remark}
        //         </Text>
                
        //       </Popover>
              
        //     </span>
        //   )
          
        // },

        

       
        {
          title: null,
          key: 'action',
          dataIndex:'action',
          fixed:'right',
          align:'center',
          width:70,
          render: (text, record) => (
            <Space size="middle">
             
              <>
                <Button size="small" onClick={()=> handlePrint(record)}><PrinterFilled /></Button>
                  
                {/* <Popconfirm
                  // placement="topRight"
                  title={"Do you want to void this? timesheet will be pending!"}
                  onConfirm={()=> handleVoid(record?.pt_id)}
                  okText="Yes"
                  cancelText="No"
                >
                    <Button size="small"><DisconnectOutlined /></Button>
                </Popconfirm> */}
              </>
              
              
              {/* <Popconfirm
                title={"Do you want to delete this timesheet?"}
                onConfirm={()=> handleDelete(record?.pt_id)}
                okText="Yes"
                cancelText="No"
              >
              <Button size="small" type="danger"><DeleteOutlined /></Button>
              </Popconfirm> */}
            </Space>
          ),
        },
      ];

    return array;
}
