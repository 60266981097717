import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  time,
  getToPayTimesheet,
  createPayment,
  sumTotalHours,
  sumArr,
  getCookie,
  createVK,
} from "../../../function/fn";
import {
  openWarningNotification,
  SelectInstructor,
  SelectStaff,
  SelectYear,
} from "../../../function/own-comp";
import { userLoginAtom } from "../../../recoils";
import reportAddTimesheet from "../tableColumn/reportAddTimesheet";

const { Option } = Select;
const { TextArea } = Input;

export default function AddReportStaff({ open, setOpen, setSuccess }) {
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [instructorId, setInstructorId] = useState(null);

  const [dataList, setDataList] = useState();

  const userLogin = useRecoilValue(userLoginAtom);

  const [sumAmount, setSumAmount] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  const [range, setRange] = useState({
    from: "",
    to: "",
  });

  const handleResize = () => {
    // 960
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  const onFinish = (values) => {
    // if(selectedRow?.length <= 0){
    //     openWarningNotification({title:'Warning',message:'No timesheet selected!'})
    //     return;
    // }

    setLoading(true);
    const newData = {
      ...values,
      startDate: range?.from,
      endDate: range?.to,
      totalAmount: sumAmount,
      totalHours: totalHours,
      isStaff: 1,
      createdBy: userLogin?.id,
      timesheets: [],
    };

    async function createData() {
      let success = await createPayment(newData);
      if (success) {
        setSuccess(true);
        setLoading(false);
        setOpen(false);
        form.resetFields();
        setDataList(null);
      } else {
        setLoading(false);
      }
    }
    createData();
  };

  const onFinishFailed = (failed) => {
    console.log("Failedd", failed);
  };

  const setToStaffFn = (e) => {
    form.setFieldsValue({
      inst_id: e?.id,
      totalAmount: parseFloat(e?.salary),
      totalHours: 0,
    });

    setSumAmount(parseFloat(e?.salary));
    setInstructorId(e?.id);
  };

  const setToYearFn = (e) => {
    form.setFieldsValue({
      forYear: e,
    });
  };

  const handleSelectDate = (e) => {
    if (e) {
      setRange({
        from: moment(e[0]).format("YYYY-MM-DD").toString(),
        to: moment(e[1]).format("YYYY-MM-DD").toString(),
      });
    } else {
      setRange({
        from: "",
        to: "",
      });
    }
  };

  return (
    <>
      <Drawer
        title="MAKE PAYMENT STAFF"
        placement="right"
        width={isMobile ? "100%" : 736}
        onClose={() => setOpen(false)}
        visible={open}
      >
        <Form
          name="addReport"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="inst_id"
                label="Staff"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <SelectStaff
                  title="staff"
                  setValue={setToStaffFn}
                  disableAddNew={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <DatePicker.RangePicker
                  onChange={(e) => handleSelectDate(e)}
                  placeholder={["start date", "end date"]}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="totalHours"
                label="Total hours"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <Input
                  size="large"
                  disabled
                  placeholder="total hours"
                  suffix={
                    loading && <LoadingOutlined style={{ fontSize: 20 }} spin />
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="totalAmount"
                label="Total amount"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <Input
                  disabled
                  placeholder="total amount"
                  size="large"
                  suffix={
                    loading && <LoadingOutlined style={{ fontSize: 20 }} spin />
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="payForMonth"
                label="Pay for month"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <Select size="large" placeholder="pay for month">
                  <Option value="January">January</Option>
                  <Option value="February">February</Option>
                  <Option value="March">March</Option>
                  <Option value="April">April</Option>
                  <Option value="May">May</Option>
                  <Option value="June">June</Option>
                  <Option value="July">July</Option>
                  <Option value="August">August</Option>
                  <Option value="September">September</Option>
                  <Option value="October">October</Option>
                  <Option value="November">November</Option>
                  <Option value="December">December</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="forYear"
                label="Year"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <SelectYear setValue={setToYearFn} title={"year"} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="remark" label="Remark">
                <TextArea
                  rows={5}
                  size="large"
                  placeholder="remark..."
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  size="large"
                  htmlType="submit"
                >
                  SET PAID
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
