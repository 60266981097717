import { LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Switch,
  Table,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  time,
  getToPayTimesheet,
  createPayment,
  sumTotalHours,
  sumArr,
  getCookie,
  createVK,
} from "../../../function/fn";
import {
  openWarningNotification,
  SelectInstructor,
  SelectYear,
} from "../../../function/own-comp";
import { userLoginAtom } from "../../../recoils";
import reportAddTimesheet from "../tableColumn/reportAddTimesheet";

const { Option } = Select;
const { TextArea } = Input;

export default function AddReport({ open, setOpen, setSuccess }) {
  const [form] = Form.useForm();
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false);

  const [instructorId, setInstructorId] = useState(null);

  const [dataList, setDataList] = useState();

  const userLogin = useRecoilValue(userLoginAtom);

  const [sumAmount, setSumAmount] = useState(0);
  const [totalHours, setTotalHours] = useState(0);

  //   const [openPrint, setOpenPrint] = useState(false);

  const [range, setRange] = useState({
    from: "",
    to: "",
  });

  const [selectedRow, setSelectedRow] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  //select row update
  const onSelectChange = (selectedRowKeys) => {
    setSelectedRowKey([...selectedRowKeys]);

    let data = [];

    selectedRowKeys?.map((e) => {
      data.push(JSON.parse(e));
    });

    setSumAmount(sumArr(data?.map((e) => e?.ts_price)));
    setTotalHours(sumTotalHours(data));

    form.setFieldsValue({
      totalAmount: sumArr(data?.map((e) => e?.ts_price)),
      totalHours: sumTotalHours(data),
    });

    setSelectedRow(data?.map((e) => e?.ts_id));
  };
  //end-----

  const handleResize = () => {
    // 960
    if (window.innerWidth <= 960) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
  }, []);

  window.addEventListener("resize", handleResize);

  useEffect(() => {
    async function fetchData() {
      let dataRecord = await getToPayTimesheet({
        inst_id: instructorId,
        startDate: range?.from,
        endDate: range?.to,
      });

      setDataList(dataRecord?.data);

      // setSumAmount(dataRecord?.sumAmount)

      // setTotalHours(sumTotalHours(dataRecord?.data))

      form.setFieldsValue({
        totalAmount: 0,
        totalHours: 0,
      });

      setLoading(false);
    }
    if (range?.from !== "" && range?.to !== "" && instructorId) {
      setLoading(true);
      fetchData();
    } else {
      setLoading(true);
      setDataList([]);
      form.setFieldsValue({
        totalAmount: null,
        totalHours: null,
      });
      setLoading(false);
    }
  }, [range, instructorId]);

  const onFinish = (values) => {
    if (selectedRow?.length <= 0) {
      openWarningNotification({
        title: "Warning",
        message: "No timesheet selected!",
      });
      return;
    }

    setLoading(true);
    const newData = {
      ...values,
      startDate: range?.from,
      endDate: range?.to,
      totalAmount: sumAmount,
      totalHours: totalHours,
      createdBy: userLogin?.id,
      timesheets: [...selectedRow],
    };

    async function createData() {
      let success = await createPayment(newData);
      if (success) {
        setSuccess(true);
        setLoading(false);
        setOpen(false);
        form.resetFields();
        setSelectedRowKey([]);
        setSelectedRow([]);
        setDataList(null);
      } else {
        setLoading(false);
      }
    }
    createData();
  };

  const onFinishFailed = (failed) => {
    console.log("Failedd", failed);
  };

  const setToInstructorFn = (e) => {
    setSelectedRowKey([]);
    form.setFieldsValue({
      inst_id: e,
    });

    setInstructorId(e);
  };

  const setToYearFn = (e) => {
    form.setFieldsValue({
      forYear: e,
    });
  };

  const handleSelectDate = (e) => {
    if (e) {
      // console.log('test')
      setSelectedRowKey([]);
      setRange({
        from: moment(e[0]).format("YYYY-MM-DD").toString(),
        to: moment(e[1]).format("YYYY-MM-DD").toString(),
      });
    } else {
      setRange({
        from: "",
        to: "",
      });
    }
  };

  return (
    <>
      {/* <InvoiceToPrint setOpen={setOpenPrint} open={openPrint} data={printData} timesheet={timesheetByPayment?.data} paymentLoading={paymentLoading} /> */}
      <Drawer
        title="MAKE PAYMENT INSTRUCTOR"
        placement="right"
        width={isMobile ? "100%" : 736}
        onClose={() => setOpen(false)}
        visible={open}
      >
        <Form
          name="addReport"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
          layout="vertical"
        >
          <Row gutter={10}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="inst_id"
                label="Intructor"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <SelectInstructor
                  title="instructor"
                  setValue={setToInstructorFn}
                  disableAddNew={true}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="date"
                label="Date"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <DatePicker.RangePicker
                  onChange={(e) => handleSelectDate(e)}
                  placeholder={["start date", "end date"]}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="totalHours"
                label="Total hours"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <Input
                  size="large"
                  disabled
                  placeholder="total hours"
                  suffix={
                    loading && <LoadingOutlined style={{ fontSize: 20 }} spin />
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="totalAmount"
                label="Total amount"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <Input
                  disabled
                  placeholder="total amount"
                  size="large"
                  suffix={
                    loading && <LoadingOutlined style={{ fontSize: 20 }} spin />
                  }
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="payForMonth"
                label="Pay for month"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <Select size="large" placeholder="pay for month">
                  <Option value="January">January</Option>
                  <Option value="February">February</Option>
                  <Option value="March">March</Option>
                  <Option value="April">April</Option>
                  <Option value="May">May</Option>
                  <Option value="June">June</Option>
                  <Option value="July">July</Option>
                  <Option value="August">August</Option>
                  <Option value="September">September</Option>
                  <Option value="October">October</Option>
                  <Option value="November">November</Option>
                  <Option value="December">December</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                name="forYear"
                label="Year"
                rules={[{ required: true, message: "Fill input!" }]}
              >
                <SelectYear setValue={setToYearFn} title={"year"} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} style={{ padding: 20 }}>
              <Table
                // showHeader={false}
                className="table-custom-list"
                columns={reportAddTimesheet()}
                rowSelection={{
                  // selectedRow:selectedRow,
                  selectedRowKeys: [...selectedRowKey],
                  onChange: onSelectChange,
                }}
                dataSource={dataList}
                rowKey={(record) =>
                  JSON.stringify({
                    ts_id: record?.ts_id,
                    ts_price: record?.ts_price,
                    time: record?.time,
                  })
                }
                pagination={false}
                sticky
                loading={loading}
              />
            </Col>

            <Col xs={24} sm={24} md={24} lg={24}>
              <Form.Item name="remark" label="Remark">
                <TextArea
                  rows={5}
                  size="large"
                  placeholder="remark..."
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  size="large"
                  htmlType="submit"
                >
                  SET PAID
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
}
