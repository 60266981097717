import {Menu,Layout,Image} from 'antd'
import React, { useState } from 'react'

import Logo from '../assets/logo-ssc.png'
import { keyMenu } from '../function/fn';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AreaChartOutlined, DollarOutlined, FileProtectOutlined, HomeOutlined } from '@ant-design/icons';

const { Sider } = Layout;
const {SubMenu} = Menu

export default function MenuSide({collapsed}) {

    const urlPath = useLocation().pathname
    
    return (
        <Sider 
            trigger={null} 
            collapsible 
            collapsed={collapsed}
            className="side-custom"
            theme='light'
        >

            <div style={{height:64,textAlign:'center'}} >
                <Image preview={false} src={Logo} style={{width:collapsed ? 50:100, marginTop:10}} />
            </div>
            <Menu 
                theme="black" 
                mode="inline" 
                selectedKeys={[keyMenu(urlPath)]}
            >

                <Menu.Item key={"../"} icon={<HomeOutlined  style={{fontSize:20}} />}>
                    <a href={"../"} > Home</a>
                </Menu.Item>
                <Menu.Item key={"/"} icon={<FileProtectOutlined  style={{fontSize:20}} />}>
                    <Link to={"/"} /> Timesheet
                </Menu.Item>
                <Menu.Item key={"/payment"} icon={<DollarOutlined style={{fontSize:20}} />}>
                    <Link to={"/payment"} /> Payment
                </Menu.Item>
                <Menu.Item key={"/report"} icon={<AreaChartOutlined style={{fontSize:20}} />}>
                    <Link to={"/report"} /> Report
                </Menu.Item>

            </Menu>
        </Sider>
    )
}




