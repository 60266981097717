import { CheckCircleOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { Popover, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React from 'react'
import { currencyFormat } from '../../../function/fn';

const {Paragraph,Text} = Typography

export default function summaryPrintCol() {
  

    const content =(e)=>(
        <Paragraph style={{fontSize:16,width:300,textAlign:'justify'}}>
          {e}
        </Paragraph>
      )
    
    var array = [
        {
          title: 'N°',
          dataIndex: 'no',
          key: 'no',
          width:30,
          // render: (text,record,i)=>(
          //   <span>
          //     <Tooltip title={moment(record?.createdAt).format("DD/MM/YYYY")}>
          //       {i+1}
          //     </Tooltip>
          //   </span>
          // )
        },

        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width:60,
            render: (text,record)=>(
              <span>
                  {moment(record?.createdAt).format('YYYY')}{String(record?.id).padStart(5,'0')}
              </span>
            )
          },

        {
            title: 'ABA',
            dataIndex: 'aba_number',
            key: 'aba_number',
            width:70,
            
          },

        {
          title: 'Description',
          dataIndex: 'name',
          key: 'name',
          width:100,
          
        },

        // {
        //     title: 'Details',
        //     dataIndex: 'forType',
        //     key: 'forType',
        //     width:60,
            
        // },

        // {
        //   title: 'Time',
        //   dataIndex: 'time',
        //   key: 'time',
        //   width:80,
       
        // },

        // {
        //     title: 'Remark',
        //     dataIndex: 'ts_remark',
        //     key: 'ts_remark',
        //     width:100,
        //     render:(text,record)=>{
        //         <Popover placement="bottom"  content={()=>content(record?.ts_remark)} title={null} trigger="hover">
        //             <Text ellipsis >
        //             {record?.ts_remark}
        //             </Text>
                
        //         </Popover>
        //     }
        // },

        {
          title: 'Amount',
          dataIndex: 'ts_price',
          key: 'ts_price',
          width:60,
          render: (text,record)=>(
            <span>
                {/* <div style={{fontSize:14}} className={record?.ts_payment === 'pending' ? "pending-badge":"success-badge"}> */}
                  { currencyFormat(record?.amount)}
                {/* </div> */}
            </span>
          )
        },
      ];

    return array;
}
